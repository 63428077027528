<template>
  <div class="main">
    <div class="page-bg"></div>
    <div class="animation-wrapper">
      <div class="particle particle-1"></div>
      <div class="particle particle-2"></div>
      <div class="particle particle-3"></div>
      <div class="particle particle-4"></div>
    </div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12">
          <v-card elevation="2" width="700" class="mt-10 pa-10 border" shaped>
            <h3 class="text-h3 font-weight-bold mb-4">
              Welcome To <br />
              <span class="goldColor">LXGMINERS</span>
            </h3>
            <h5 class="subheading font-weight-normal pa-3 text-center">
              The industry leader in providing the most guaranteed pool mining
              service. Get started and earn TODAY!
            </h5>
            <v-divider />
            <h6 class="text-h6 text-center font-weight-bold mb-4">
              Create an Account
            </h6>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="fullName"
                  label="Fullname"
                  filled
                ></v-text-field>

                <v-select
                  :items="items"
                  filled
                  label="Select Country"
                ></v-select>
                <v-text-field
                  v-model="password"
                  label="Password"
                  type="password"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="email"
                  label="Email"
                  filled
                ></v-text-field>

                <v-text-field
                  v-model="userName"
                  label="UserName"
                  filled
                ></v-text-field>
                <v-btn
                  @click="signup()"
                  block
                  class="mt-2"
                  large
                  color="#d4b506"
                  light
                >
                  Create Account
                </v-btn>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-checkbox
                color="#d4b506"
                label="I agree with Terms and conditions"
              ></v-checkbox>
              <v-spacer />
              <span class="sideNav"> Have an account? </span>
              <v-btn text color="#d4b506" @click="navigate('login')">
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-bottom-sheet v-model="openOTP" inset persistent>
        <v-sheet class="text-center" height="200px">
          <div class="text-center">Enter the OTP sent to {{ email }}</div>
          <div>
            <div class="ma-auto position-relative" style="max-width: 300px">
              <v-otp-input
                v-model="otp"
                :disabled="loading"
                @finish="onFinish"
                autofocus
              ></v-otp-input>
              <v-overlay absolute :value="loading">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-overlay>
            </div>
            <v-btn class="mt-6" text color="error" @click="closeOtp()">
              Resend
            </v-btn>
            <v-btn class="mt-6" text color="error" @click="closeOtp('true')">
              Close
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </v-container>
  </div>
</template>
<script>
const countryList = require("country-list");

export default {
  data: () => ({
    password: "",
    email: "",
    fullName: "",
    userName: "",
    items: [],
    otp: "",
    referralCode: "",
  }),
  methods: {
    navigate(payload) {
      this.$router.push({ name: payload });
    },
    onFinish(rsp) {
      let payload = {
        email: this.email,
        activationCode: rsp,
        route: this.$router,
      };
      this.$store.dispatch("VerifyUser", payload);
    },

    signup() {
      if (
        this.password == "" ||
        this.email == "" ||
        this.fullName == "" ||
        this.userName == ""
      ) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Fill All The Required Field");
        return;
      }
      let payload = {
        password: this.password,
        fullName: this.fullName,
        userName: this.userName,
        email: this.email,
        referralCode: this.referralCode,
      };
      this.$store.dispatch("Signup", payload);
    },
    closeOtp(payload) {
      if (payload) {
        this.$store.commit("setOpenOtp", false);
      } else {
        this.$store.commit("setOpenOtp", false);
        this.signup();
      }
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    openOTP() {
      return this.$store.getters.openOTP;
    },
  },
  created() {
    this.items = countryList.getNames();
    this.referralCode = localStorage.getItem("referralCode");
  },
};
</script>
  
<style lang="scss" scoped>
.main {
  flex: 1;
  background-color: black;
  height: 100vh;
}
.position-relative {
  position: relative;
}

.border {
  border: 2px solid #d4b506 !important;
}
.section-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55vh;
}
.section-2 {
  background-color: rgb(5, 5, 5, 0.8) !important;
  margin: 5em;
}
.goldColor {
  color: #d4b506 !important;
}
.textWhite {
  color: white;
}
/* Config */
$color-bg: #1e1e1e;
$color-particle: #fff;
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 200s;

/* mixins */
@function particles($max) {
  $val: 0px 0px $color-particle;
  @for $i from 1 through $max {
    $val: #{$val}, random($spacing) + px random($spacing) + px $color-particle;
  }
  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
  border-radius: 50%;
}

/* Styles */
html,
body {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  overflow: hidden;
}

.page-bg,
.animation-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-bg {
  background: $color-bg;
  background-blend-mode: screen;
  background-size: cover;
  filter: grayscale(100%);
  z-index: -1;
}

.particle,
.particle:after {
  background: transparent;
}

.particle:after {
  position: absolute;
  content: "";
  top: $spacing;
}

.particle-1 {
  animation: animParticle $time-1 linear infinite;
  @include particles(1000);
  height: 2px;
  width: 2px;
}

.particle-1:after {
  @include particles(900);
  height: 2px;
  width: 2px;
}

.particle-2 {
  animation: animParticle $time-2 linear infinite;
  @include particles(800);
  height: 2px;
  width: 2px;
}

.particle-2:after {
  @include particles(500);
  height: 3px;
  width: 3px;
}

.particle-3:after {
  @include particles(1100);
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.particle-4 {
  animation: animParticle $time-4 linear infinite;
  @include particles(1200);
  height: 1px;
  width: 1px;
}

.particle-4:after {
  @include particles(700);
  height: 1px;
  width: 1px;
}

@keyframes animParticle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY($spacing * -1);
  }
}

.page-wrapper {
  text-align: center;
  color: #fff;
  z-index: 2;
}

h4 {
  color: #fff;
  font-family: Monoton;
  -webkit-animation: neon1 1.5s ease-in-out infinite alternate;
  -moz-animation: neon1 1.5s ease-in-out infinite alternate;
  animation: neon1 1.5s ease-in-out infinite alternate;
}

h4:hover {
  color: #ff1177;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}

/*glow for webkit*/

@-webkit-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow for mozilla*/

@-moz-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow*/

@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}
@media only screen and (max-width: 800px) {
  .sideNav {
    display: none;
  }
  .menuBtn {
    display: inline;
  }
  .logoDiv {
    display: none;
  }
}
</style>
  